import UnpublishedIcon from '@mui/icons-material/Unpublished';
import VerifiedIcon from '@mui/icons-material/Verified';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableFooter,
	TablePagination,
	CircularProgress,
	Tooltip,
	IconButton,
} from '@mui/material';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../utils/formatDate';
import { getAllReserves, updateReserveState } from '../../redux/actions/admin/reserves';

export const ReservasList = ({ state, user, reservas, count }) => {
	const dispatch = useDispatch();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [reservasItem, setReservasItem] = useState(null);

	const isLoading = useSelector(state => state.allReserves.loading);

	useEffect(() => {
		setPage(0);
	}, [state]);

	useEffect(() => {
		setReservasItem(reservas);
	}, [reservas]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		window.scrollTo({ behavior: 'smooth', top: 'top' });
		dispatch(getAllReserves({ active: state, limit: rowsPerPage, offset: newPage }));
	};

	const handleRowsPerPage = e => {
		setPage(0);
		setRowsPerPage(e.target.value);
		dispatch(getAllReserves({ active: state, limit: e.target.value, offset: 0 }));
	};

	const cancelBooking = (e, actualState) => {
		setReservasItem(reservasItem.filter(element => element.id !== e.id));

		dispatch(
			updateReserveState(
				e.id,
				{
					state: 'reservaCancelada',
					lotId: e.LotDetailId,
					selled: 'Disponible',
					ContactId: e.ContactId,
					reserva: { active: 'Cancelado' },
					numberLot: e.LotDetail.numberLot,
					userName: user.fullName,
					developmentName: e.LotDetail.Development.name,
					developmentId: e.LotDetail.Development.id,
				},
				actualState
			)
		);
	};

	const extendeBooking = (e, actualState) => {
		setReservasItem(reservasItem.filter(element => element.id !== e.id));

		dispatch(
			updateReserveState(
				e.id,
				{
					state: 'extenderReserva',
					lotId: e.LotDetailId,
					ContactId: e.ContactId,
					reserva: { active: 'Cancelado' },
					numberLot: e.LotDetail.numberLot,
					userName: user.fullName,
					developmentName: e.LotDetail.Development.name,
					developmentId: e.LotDetail.Development.id,
				},
				actualState
			)
		);
	};

	const confirmBooking = (e, actualState) => {
		setReservasItem(reservasItem.filter(element => element.id !== e.id));

		dispatch(
			updateReserveState(
				e.id,
				{
					state: 'ventaConfirmada',
					lotId: e.LotDetailId,
					selled: 'Disponible',
					ContactId: e.ContactId,
					reserva: { active: 'Cancelado' },
					numberLot: e.LotDetail.numberLot,
					developmentName: e.LotDetail.Development.name,
					userName: user.fullName,
					developmentId: e.LotDetail.Development.id,
				},
				actualState
			)
		);
	};

	return (
		<>
			{isLoading ? (
				<div
					style={{
						width: '100%',
						height: '100%',
						marginTop: '100px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<Card>
					<Scrollbar>
						<TableContainer sx={{ width: '100%' }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell align='left'>ID</TableCell>
										<TableCell align='left'>Asesor</TableCell>
										<TableCell align='left'>Contacto</TableCell>
										<TableCell align='left'>Desarrollo</TableCell>
										<TableCell align='left'>Número de lote</TableCell>
										<TableCell align='right'>Fecha de reserva</TableCell>
										<TableCell align='right'>Fecha de vencimiento</TableCell>
										<TableCell align='right'></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{reservasItem?.map(e => {
										if (e.ContactId === null) return null;
										return (
											<TableRow key={e.id}>
												<TableCell align='left'>{e.id}</TableCell>

												<TableCell align='left'>{e.User?.fullName}</TableCell>

												<TableCell align='left'>
													<Link to={`/contacto/${e.ContactId}`}>
														{e.Contact?.name} {e.Contact?.lastName}
													</Link>
												</TableCell>

												<TableCell align='left' sx={{ maxWidth: '500px !important' }}>
													{e.LotDetail?.Development?.name}
												</TableCell>

												<TableCell align='left'>{e.LotDetail?.numberLot}</TableCell>

												<TableCell align='right'>{formatDate(e.createdAt)}</TableCell>
												<TableCell align='right'>{formatDate(e.fechaVencimiento)}</TableCell>

												{state === 'reservasVencidas' && (
													<TableCell sx={{ display: 'flex' }}>
														<Tooltip title='Extender reserva' placement='top'>
															<IconButton
																onClick={() => extendeBooking(e, 'reservasVencidas')}
																aria-label='Extender reserva'
																color='warning'
															>
																<PlusOneIcon />
															</IconButton>
														</Tooltip>

														<Tooltip title='Cancelar reserva' placement='top'>
															<IconButton
																onClick={() => cancelBooking(e, 'reservasVencidas')}
																aria-label='Cancelar reserva'
																color='error'
															>
																<UnpublishedIcon />
															</IconButton>
														</Tooltip>

														<Tooltip
															onClick={() => confirmBooking(e, 'reservasVencidas')}
															title='Confirmar compra'
															placement='top'
														>
															<IconButton aria-label='Confirmar compra' color='success'>
																<VerifiedIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												)}

												{state === 'Reservado' && (
													<TableCell sx={{ display: 'flex' }}>
														<Tooltip title='Cancelar reserva' placement='top'>
															<IconButton
																onClick={() => cancelBooking(e, 'Reservado')}
																aria-label='Cancelar reserva'
																color='error'
															>
																<UnpublishedIcon />
															</IconButton>
														</Tooltip>

														<Tooltip
															onClick={() => confirmBooking(e, 'Reservado')}
															title='Confirmar compra'
															placement='top'
														>
															<IconButton aria-label='Confirmar compra' color='success'>
																<VerifiedIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												)}
											</TableRow>
										);
									})}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TablePagination
											page={page}
											count={count}
											rowsPerPage={rowsPerPage}
											onPageChange={handleChangePage}
											rowsPerPageOptions={[10, 25, 50, 100]}
											labelRowsPerPage='Mostrar por pagina: '
											onRowsPerPageChange={handleRowsPerPage}
										/>
									</TableRow>
								</TableFooter>
							</Table>
						</TableContainer>
					</Scrollbar>
				</Card>
			)}
		</>
	);
};
