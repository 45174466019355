import { useRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Card, CardHeader, CardContent, Typography, Button, Box } from '@mui/material';

export const DragDrop = ({
	title,
	files = [],
	setFiles,
	isMultiple = false,
	handleDelete,
	style = { maxWidth: '100%', margin: '0 auto 14px', maxHeight: '350px' },
	text = 'Arrastrá y soltá el archivo',
	loadingDelete = false,
	loading = false,
	handleSubmit,
	accept = '*',
	save = true,
	name,
}) => {
	const [dragActive, setDragActive] = useState(false);
	const inputRef = useRef(null);

	const handleFiles = fileList => {
		const newFiles = Array.from(fileList);

		setFiles(prevState => (Array.isArray(prevState) ? [...prevState, ...newFiles] : newFiles));
	};

	const handleChange = e => {
		e.preventDefault();
		if (e.target.files) {
			handleFiles(e.target.files);
		}
	};

	const handleDrop = e => {
		e.preventDefault();
		e.stopPropagation();

		setDragActive(false);
		if (e.dataTransfer.files) {
			handleFiles(e.dataTransfer.files);
		}
	};

	const handleDragLeave = e => {
		e.preventDefault();
		e.stopPropagation();

		setDragActive(false);
	};

	const handleDragOver = e => {
		e.preventDefault();
		e.stopPropagation();

		setDragActive(true);
	};

	const handleDragEnter = e => {
		e.preventDefault();
		e.stopPropagation();

		setDragActive(true);
	};

	const removeFile = idx => {
		const newArr = files.filter((_, index) => index !== idx);
		setFiles(newArr);
	};

	const openFileExplorer = () => {
		inputRef.current.value = '';
		inputRef.current.click();
	};

	return (
		<Card>
			<CardHeader title={title} />

			<CardContent
				onDragEnter={!isMultiple && files.length > 0 ? null : handleDragEnter}
				onDrop={!isMultiple && files.length > 0 ? null : handleDrop}
				onDragLeave={!isMultiple && files.length > 0 ? null : handleDragLeave}
				onDragOver={!isMultiple && files.length > 0 ? null : handleDragOver}
				sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}
			>
				{files.length > 0 && !isMultiple ? (
					<div>
						{files.map((file, index) => (
							<Box key={index}>
								<img
									src={typeof file === 'string' ? file : URL.createObjectURL(file)}
									alt={`file preview ${index}`}
									style={style}
								/>

								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<LoadingButton
										type='button'
										size='small'
										color='error'
										variant='contained'
										loadingPosition='start'
										loading={loadingDelete}
										startIcon={<DeleteIcon />}
										onClick={() => handleDelete(name)}
									>
										Eliminar
									</LoadingButton>

									{save && (
										<LoadingButton
											type='button'
											size='small'
											color='success'
											loading={loading}
											variant='contained'
											loadingPosition='end'
											endIcon={<SaveIcon />}
											onClick={() => handleSubmit(name)}
										>
											Guardar
										</LoadingButton>
									)}
								</Box>
							</Box>
						))}
					</div>
				) : (
					<>
						<input
							style={{ display: 'none' }}
							ref={inputRef}
							type='file'
							multiple={isMultiple}
							onChange={handleChange}
							accept={accept}
							name={name}
						/>

						<UploadFileIcon fontSize='large' color='primary' />

						<Typography>{text}</Typography>

						<Button size='small' variant='contained' type='button' onClick={openFileExplorer}>
							Buscar archivos
						</Button>

						{files.length > 0 && isMultiple ? (
							<div style={{ marginTop: 24 }}>
								{files.map((file, index) => (
									<div key={index}>
										<img src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
										<Button
											type='button'
											variant='contained'
											startIcon={<DeleteIcon />}
											onClick={() => removeFile(index)}
										>
											Eliminar
										</Button>
									</div>
								))}
							</div>
						) : null}
					</>
				)}
			</CardContent>
		</Card>
	);
};
