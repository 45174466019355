import {
	Stack,
	TableCell,
	TableRow,
	Typography,
	IconButton,
	Tooltip,
	TableBody,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { WarningDeleteDevelopment } from '../Dialogs';
import { avatarDesarrollo } from '../../assets/avatars';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

import { downloadDevelopmentData } from '../../redux/actions/admin/developments';

export const ItemListDesarrollo = ({ e }) => {
	const dispatch = useDispatch();

	const [openWarningDelete, setOpenWarningDelete] = useState(false);

	return (
		<>
			<TableBody>
				<TableRow hover>
					<TableCell>
						<>
							{e.logo ? (
								<img src={`${e.logo}`} alt='Logo desarrollo' height={80} width={80} />
							) : (
								<img src={avatarDesarrollo} alt='Logo desarrollo' height={40} width={40} />
							)}
						</>
					</TableCell>

					<TableCell>
						<Stack alignItems='center' direction='row' spacing={2}>
							<Typography variant='subtitle2'>{e.name}</Typography>
						</Stack>
					</TableCell>

					<TableCell>{e.province}</TableCell>
					<TableCell>{e.totLot}</TableCell>
					<TableCell>{e.availableLots}</TableCell>

					<TableCell sx={{ textAlign: 'end' }}>
						<Tooltip title='Descargar datos'>
							<IconButton onClick={() => dispatch(downloadDevelopmentData(e.id))}>
								<SimCardDownloadOutlinedIcon fontSize='small' color='primary' />
							</IconButton>
						</Tooltip>

						<Tooltip title='Editar'>
							<Link to={`/editar/desarrollo/${e.id}`}>
								<IconButton>
									<ModeEditIcon fontSize='small' color='success' />
								</IconButton>
							</Link>
						</Tooltip>

						<Tooltip title='Borrar'>
							<IconButton onClick={() => setOpenWarningDelete(true)}>
								<DeleteIcon fontSize='small' sx={{ color: 'red' }} />
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
			</TableBody>
			<WarningDeleteDevelopment open={openWarningDelete} setOpen={setOpenWarningDelete} id={e.id} />
		</>
	);
};
