import React, { useState, useEffect } from 'react';
import {
	Box,
	Card,
	Button,
	styled,
	Divider,
	Container,
	Typography,
	CardHeader,
	CardContent,
	CircularProgress,
	tableCellClasses,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	SvgIcon,
} from '@mui/material';
import { useSelector } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Map } from '../components/Map';
import { DesarrolloPerfilLotes } from '../components/Desarrollos';
import { DesarrolloPerfilMap } from '../components/InteractiveMaps';

import 'react-quill/dist/quill.snow.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#1b263b',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 17,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const DesarrolloPerfil = () => {
	const data = useSelector(state => state.developmentsUser.developmentPerfil);
	const loading = useSelector(state => state.developmentsUser.loadingPerfil);
	const [desarrolloPlano, setDesarrolloPlano] = useState(null);

	const [loadingMap, setLoadingMap] = useState(true);

	const [coordinates, setCoordinates] = useState([0, 0]);

	const [descriptionValue, setDescriptionValue] = useState(null);

	useEffect(() => {
		if (data?.development?.description) {
			setDescriptionValue(data.development.description);
		}

		if (data?.development?.coordinates) {
			setCoordinates(data.development.coordinates);
			setLoadingMap(false);
		}

		const checkImageExists = async () => {
			try {
				const response = await fetch(data.plane);
				if (response.ok) {
					setDesarrolloPlano(true);
				} else {
					setDesarrolloPlano(false);
				}
			} catch (error) {
				setDesarrolloPlano(false);
			}
		};

		checkImageExists();
	}, [data]);

	const handleGoBack = () => window.history.back();

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
				}}
			>
				<Container maxWidth='xl'>
					<Button
						onClick={() => handleGoBack()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver atrás
					</Button>
					{loading ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								height: '350px',
							}}
						>
							<CircularProgress />
							<Typography sx={{ marginTop: 5 }}>Esto puede tardar unos segundos...</Typography>
						</div>
					) : (
						<>
							<Card>
								<CardContent sx={{ padding: 0 }}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										{data?.logo && <img src={data?.logo} alt='Logo' height={320} width={320} />}
									</Box>
								</CardContent>
								<Divider />
							</Card>
							<Card sx={{ mt: 3 }}>
								<CardHeader title='Descripción del desarrollo' />
								<CardContent>
									<Box
										sx={{
											display: 'flex',
											height: '400px',
											flexDirection: 'column',
										}}
									>
										<div dangerouslySetInnerHTML={{ __html: descriptionValue }}></div>
										<Divider />
									</Box>
								</CardContent>
								<Divider />
							</Card>

							<Card sx={{ mt: 3 }}>
								<CardHeader title='Lotes' />
								<CardContent>
									{!desarrolloPlano ? (
										<DesarrolloPerfilLotes lots={data.lots} />
									) : (
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<TableContainer
												sx={{
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												<Table
													sx={{
														maxWidth: '50%',
													}}
												>
													<TableHead>
														<TableRow>
															<StyledTableCell align='center' sx={{ borderTopLeftRadius: '5px' }}>
																Lotes
															</StyledTableCell>

															<StyledTableCell align='center'>Lotes disponibles</StyledTableCell>

															<StyledTableCell align='center' sx={{ borderTopRightRadius: '5px' }}>
																Lotes vendidos
															</StyledTableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<StyledTableRow>
															<StyledTableCell
																align='center'
																sx={{ borderBottomLeftRadius: '5px' }}
															>
																{data.totalLots}
															</StyledTableCell>

															<StyledTableCell align='center'>{data.availableLots}</StyledTableCell>

															<StyledTableCell
																align='center'
																sx={{ borderBottomRightRadius: '5px' }}
															>
																{Number(data.totalLots) - Number(data.availableLots)}
															</StyledTableCell>
														</StyledTableRow>
													</TableBody>
												</Table>
											</TableContainer>

											<div style={{ height: '100%' }}>
												<DesarrolloPerfilMap development={data.plane} lotes={data.lots} />
											</div>
										</Box>
									)}
								</CardContent>
								<Divider />
							</Card>

							<Card sx={{ mt: 3 }}>
								<CardHeader title='Localización' />
								<CardContent>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Typography gutterBottom variant='body2' sx={{ textAlign: 'justify' }}>
											{data.locality}
										</Typography>

										<Typography color='text.secondary' variant='body2'>
											{data.address}
										</Typography>

										{loadingMap ? (
											<div
												style={{
													display: 'flex',
													width: '100%',
													height: '150px',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<CircularProgress />
											</div>
										) : (
											<div style={{ height: '450px' }}>
												<Map coordinates={coordinates} name={data.development.name} />
											</div>
										)}
									</Box>
								</CardContent>
								<Divider />
							</Card>

							<br />
						</>
					)}
				</Container>
			</Box>
		</>
	);
};
