import {
	Box,
	Stack,
	Container,
	Pagination,
	Unstable_Grid2 as Grid,
	OutlinedInput,
	Typography,
	MenuItem,
	Select,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useSelector, useDispatch } from 'react-redux';

import { DevelopmentCard } from '../components/Desarrollos';
import {
	getAllDevelopmentEnded,
	getAllDevelopmentResume,
} from '../redux/actions/user/developments';

export const Desarrollos = () => {
	const developmentsActive = useSelector(state => state.developmentsUser.developmentsActive);
	const developmentsEnded = useSelector(state => state.developmentsUser.developmentsEnded);
	const loadingActives = useSelector(state => state.developmentsUser.loadingActive);
	const loadingEnded = useSelector(state => state.developmentsUser.loadingEnded);
	const dispatch = useDispatch();

	const [page, setPage] = useState(1);
	const [pageEnded, setPageEnded] = useState(1);

	const [province, setProvince] = useState('');
	const [provinceEnded, setProvinceEnded] = useState('');

	const handleChange = (e, value) => {
		setPage(value);
	};

	const handleChangeEnded = (e, value) => {
		setPageEnded(value);
	};

	useEffect(() => {
		dispatch(
			getAllDevelopmentResume({
				page,
				perPage: 6,
				querys: province.length > 1 ? `province=${province}` : '',
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, province]);

	useEffect(() => {
		dispatch(
			getAllDevelopmentEnded({
				pageEnded,
				perPage: 6,
				querys: provinceEnded.length > 1 ? `province=${provinceEnded}` : '',
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageEnded, provinceEnded]);

	const handleChangeProvince = e => {
		setPage(1);
		setProvince(e.target.value);
	};

	const handleChangeProvinceEnded = e => {
		setPageEnded(1);
		setProvinceEnded(e.target.value);
	};

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack
							direction='row'
							justifyContent='space-between'
							spacing={4}
							sx={{ paddingLeft: 2, paddingRight: 2 }}
						>
							<Stack spacing={1}>
								<Typography variant='h4'>Desarrollos activos</Typography>
							</Stack>

							<Select
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								value={province}
								onChange={handleChangeProvince}
								input={<OutlinedInput />}
								name='province'
								label='Provincia..'
								sx={{ width: '250px' }}
							>
								<MenuItem disabled value=''>
									<em>Provincia...</em>
								</MenuItem>
								<MenuItem value='Buenos Aires'>Buenos Aires</MenuItem>
								<MenuItem value='Mendoza'>Mendoza</MenuItem>
							</Select>
						</Stack>
						{loadingActives ? (
							<Grid container spacing={3}>
								{Array.from({ length: 6 }).map((_, index) => (
									<Grid xs={12} md={6} lg={4} key={index}>
										<Skeleton
											key={index}
											variant='rectangular'
											width={440}
											height={254}
											sx={{ borderRadius: '20px' }}
										/>
									</Grid>
								))}
							</Grid>
						) : (
							<>
								<Grid container spacing={3} sx={{ minHeight: '550px' }}>
									{developmentsActive?.developments.length > 0 ? (
										developmentsActive?.developments.map(e => {
											return (
												<Grid xs={12} md={6} lg={4} key={e.id} sx={{ maxHeight: '280px' }}>
													<Link to={`/desarrollo/${e.id}`} style={{ textDecoration: 'none' }}>
														<DevelopmentCard
															availableLots={e.availableLots}
															imagePreview={e.desarrollo}
															locality={e.locality}
															address={e.address}
															totLot={e.totLot}
															name={e.name}
														/>
													</Link>
												</Grid>
											);
										})
									) : (
										<Box
											height={558}
											sx={{
												width: '100vw',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography variant='h6' color='primary'>
												No se encontraron desarrollos
											</Typography>
										</Box>
									)}
								</Grid>
							</>
						)}

						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Pagination
								page={page}
								size='small'
								defaultPage={1}
								onChange={handleChange}
								count={developmentsActive.totalPages}
							/>
						</Box>
					</Stack>
				</Container>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<Stack spacing={1}>
								<Typography variant='h4' color='grey'>
									Finalizados
								</Typography>
							</Stack>

							<Select
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								value={provinceEnded}
								onChange={handleChangeProvinceEnded}
								input={<OutlinedInput />}
								name='province'
								label='Provincia..'
								sx={{ width: '250px' }}
							>
								<MenuItem disabled value=''>
									<em>Provincia...</em>
								</MenuItem>
								<MenuItem value='Buenos Aires'>Buenos Aires</MenuItem>
								<MenuItem value='Mendoza'>Mendoza</MenuItem>
							</Select>
						</Stack>
						{loadingEnded ? (
							<Grid container spacing={3}>
								{Array.from({ length: 6 }).map((_, index) => (
									<Grid xs={12} md={6} lg={4} key={index}>
										<Skeleton
											key={index}
											variant='rectangular'
											width={440}
											height={254}
											sx={{ borderRadius: '20px' }}
										/>
									</Grid>
								))}
							</Grid>
						) : (
							<>
								<Grid container spacing={3} sx={{ minHeight: '550px' }}>
									{developmentsEnded?.developments.length > 0 ? (
										developmentsEnded?.developments.map(e => {
											return (
												<Grid xs={12} md={6} lg={4} key={e.id} sx={{ maxHeight: '280px' }}>
													<Link to={`/desarrollo/${e.id}`} style={{ textDecoration: 'none' }}>
														<DevelopmentCard
															availableLots={e.availableLots}
															imagePreview={e.desarrollo}
															locality={e.locality}
															address={e.address}
															totLot={e.totLot}
															name={e.name}
														/>
													</Link>
												</Grid>
											);
										})
									) : (
										<Box
											height={558}
											sx={{
												width: '100vw',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Typography variant='h6' color='primary'>
												No se encontraron desarrollos
											</Typography>
										</Box>
									)}
								</Grid>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Pagination
										page={pageEnded}
										size='small'
										defaultPage={1}
										onChange={handleChangeEnded}
										count={developmentsEnded.totalPages}
									/>
								</Box>
							</>
						)}
					</Stack>
				</Container>
			</Box>
		</>
	);
};
