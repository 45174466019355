import { useRef, useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';

const UpdateMapCenter = ({ position }) => {
	const map = useMap();

	useEffect(() => {
		if (position) {
			map.setView(position, map.getZoom());
		}
	}, [map, position]);
	return null;
};

export const Map = ({ coordinates, name }) => {
	const markerRef = useRef(null);

	const [position, setPosition] = useState(null);

	useEffect(() => {
		if (!Array.isArray(coordinates)) {
			const arr = coordinates.slice(1, -1).split(',').map(Number);

			setPosition(arr);
		} else {
			setPosition(coordinates);
		}
	}, [coordinates]);

	if (!position) return null;

	return (
		<MapContainer
			zoom={14}
			center={position}
			scrollWheelZoom={false}
			style={{ height: '100%', width: '100%', borderRadius: '7px' }}
		>
			<TileLayer
				url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibGF1dGFyb2QiLCJhIjoiY2xndXdpdnMyMGsyMTNmbnVjdXdmNmJ5ayJ9.qGFpVJUPZM3EbLEeJitP4A`}
			/>
			<UpdateMapCenter position={position} />
			<Marker position={position} ref={markerRef} animate={true}>
				<Popup>{name}</Popup>
			</Marker>
		</MapContainer>
	);
};
