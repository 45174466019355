import L, { CRS } from 'leaflet';
import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, ImageOverlay, Polygon, Popup } from 'react-leaflet';

import { PopupLoteDesarrollo } from './PopupLoteDesarrollo';

const available = { fillOpacity: 0.5, color: '#11D30080', strokeWidth: '0px' };
const selled = { fillOpacity: 0.9, color: '#E8000080', strokeWidth: '0px' };
const reserved = { fillOpacity: 0.9, fillColor: '#FEDB2280', strokeWidth: '0px' };

export const DesarrolloPerfilMap = ({ development, lotes }) => {
	const mapRef = useRef(null);
	const [imageBounds, setImageBounds] = useState(null);
	const [backgroundImage, setBackgroundImage] = useState(null);
	const [forceKeyUpdate, setForceKeyUpdate] = useState(0);
	const [center, setCenter] = useState([0, 0]);

	// Medidas para el contenedor principal
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (development.length < 1) return;
		const image = new Image();
		image.src = development;

		image.onload = function () {
			const imageWidth = image.width;
			const imageHeight = image.height;

			const southWest = mapRef.current.unproject([0, imageHeight], mapRef.current.getMaxZoom());
			const northEast = mapRef.current.unproject([imageWidth, 0], mapRef.current.getMaxZoom());

			const centerLat = (southWest.lat + northEast.lat) / 2;
			const centerLng = (southWest.lng + northEast.lng) / 2;

			setImageBounds(L.latLngBounds(southWest, northEast));
			setCenter([centerLat, centerLng]);

			setBackgroundImage(image);
			setForceKeyUpdate(prevKey => prevKey + 1);
		};

		return () => {
			image.onload = null;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (backgroundImage === null) return;
		const imageWidth = backgroundImage.width;
		const imageHeight = backgroundImage.height;

		setWidth(imageWidth + 150);
		setHeight(imageHeight + 150);

		setForceKeyUpdate(prevKey => prevKey + 1);
	}, [backgroundImage]);

	return (
		<>
			<span style={{ display: 'flex', justifyContent: 'center' }}>
				<MapContainer
					key={forceKeyUpdate}
					ref={mapRef}
					center={center}
					zoom={25}
					scrollWheelZoom={false}
					dragging={false}
					doubleClickZoom={false}
					zoomControl={false}
					tap={false}
					crs={CRS.Simple}
					keyboardPanDelta={false}
					style={{
						width: `${width}px`,
						height: `${height}px`,
						border: '1px solid #1b263b',
						marginTop: '20px',
						borderRadius: '7px',
						userSelect: 'none',
					}}
				>
					<TileLayer url='' />
					{imageBounds && <ImageOverlay url={backgroundImage.src} bounds={imageBounds} />}

					{lotes.map(e => {
						if (!e.coordinates) return null;
						if (!mapRef.current) return null;

						let coordinates = JSON.parse(e.coordinates);

						if (Array.isArray(coordinates[0][0])) {
							coordinates = coordinates.map(polygon =>
								polygon.map(([x, y]) => {
									const latLng = mapRef.current.unproject([x, y], mapRef.current.getMaxZoom());
									return { lat: latLng.lat, lng: latLng.lng };
								})
							);
						}

						return (
							<Polygon
								stroke={0}
								key={e.numberLot}
								positions={coordinates}
								pathOptions={
									e.selled === 'Disponible' ? available : e.selled === 'Vendido' ? selled : reserved
								}
							>
								<Popup minWidth={150} autoPan={false}>
									<PopupLoteDesarrollo contact={e} />
								</Popup>
							</Polygon>
						);
					})}
				</MapContainer>
			</span>
		</>
	);
};
