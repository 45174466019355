import React, { useState } from 'react';
import {
	Box,
	Card,
	// CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormControl,
	Select,
	MenuItem,
	Unstable_Grid2 as Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CallIcon from '@mui/icons-material/Call';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import { updateClient } from '../../redux/actions/client';
import { provinces } from '../../lib/provinces';

export const ContactProfileDetails = ({ client, user, isDirty, setAsignLot, setAsignVisit }) => {
	const dispatch = useDispatch();
	const [touch, setTouch] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: initialValues(client),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				if (!touch) return toast.warning('No has editado nada!', { theme: 'colored' });
				if (client.lastNote === formValue.description)
					return toast.warning('Actualiza la última nota', { theme: 'colored' });
				if (formik.isSubmitting) setIsLoading(true);

				await dispatch(updateClient(client.id, formValue));
				setIsLoading(false);
				isDirty(false);
				return toast.success('Contacto editado con éxito', { theme: 'colored' });
			} catch (error) {
				console.error(error);
				return toast.error('Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
			}
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit} style={{ position: 'relative' }}>
				<Card>
					<CardHeader
						subheader='Esta información puede ser editada'
						title='Perfil contacto'
						action={
							<FormControl sx={{ m: 1, minWidth: 250 }} size='small'>
								<Select
									labelId='demo-select-small'
									id='demo-select-small'
									name='state'
									value={formik.values.state}
									onChange={e => {
										formik.handleChange(e);
										isDirty(true);
										setTouch(true);
									}}
									error={formik.errors.state}
									placeholder='Estado...'
									inputProps={{ 'aria-label': 'Without label' }}
									sx={{ 'div:nth-of-type(1)': { display: 'flex !important' } }}
									disabled={
										(formik.values.state === 7 || formik.values.state === 6) && user.roleId !== 1
									}
								>
									<MenuItem value={1}>
										<PriorityHighIcon sx={{ marginRight: 1, color: 'red' }} />
										Sin contactar
									</MenuItem>

									<MenuItem value={2}>
										<CallIcon sx={{ marginRight: 1, color: '#fdc500' }} />
										Asesoramiento telefónico
									</MenuItem>

									<MenuItem value={3}>
										<SpatialAudioIcon sx={{ marginRight: 1, color: '#6cd4ff' }} />
										Asesoramiento presencial
									</MenuItem>

									<MenuItem value={6} disabled={user.roleId !== 1}>
										<TextSnippetIcon sx={{ marginRight: 1, color: '#00b4d8' }} />
										Reservado
									</MenuItem>

									<MenuItem value={4} disabled={user.roleId !== 1}>
										<CheckCircleIcon sx={{ marginRight: 1, color: '#55a630' }} />
										Alta
									</MenuItem>

									<MenuItem value={5}>
										<CancelIcon sx={{ marginRight: 1, color: '#bc4749' }} />
										Baja
									</MenuItem>

									<MenuItem value={7} disabled={user.roleId !== 1}>
										<NotInterestedIcon sx={{ marginRight: 1, color: '#bc4749' }} />
										Reserva caída
									</MenuItem>
								</Select>
							</FormControl>
						}
					/>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Nombre'
										name='name'
										value={formik.values.name}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={formik.errors.name}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Apellido'
										name='lastName'
										value={formik.values.lastName}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={formik.errors.lastName}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Dirección de email'
										name='email'
										value={formik.values.email ? formik.values.email : ''}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={!!formik.errors.email}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Número de telefóno'
										name='cellphone'
										type='number'
										value={formik.values.cellphone ? formik.values.cellphone : ''}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										onWheel={e => e.target.blur()}
										error={formik.errors.cellphone}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Localidad'
										name='locality'
										value={formik.values.locality ? formik.values.locality : ''}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={!!formik.errors.locality}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Provincia'
										name='province'
										select
										SelectProps={{ native: true }}
										value={formik.values.province ? formik.values.province : 'nodata'}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={formik.errors.province}
									>
										{provinces.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Dirección'
										name='address'
										value={formik.values.address}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={!!formik.errors.address}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Fecha de nacimiento'
										name='birthdate'
										type='date'
										value={formik.values.birthdate}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={!!formik.errors.birthdate}
									/>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										select
										SelectProps={{ native: true }}
										label='Tipo de documento'
										name='typeDoc'
										value={formik.values.typeDoc ? formik.values.typeDoc : 1}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={!!formik.errors.typeDoc}
									>
										<option value={1}>DNI</option>
										<option value={2}>Pasaporte</option>
										<option value={3}>LE</option>
									</TextField>
								</Grid>
								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Número / Código'
										name='numberId'
										value={formik.values.numberId ? formik.values.numberId : ''}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={formik.errors.numberId}
									/>
								</Grid>

								<Grid xs={12} md={12}>
									<div
										style={{
											border: '2px solid #023047',
											height: '30px',
											borderRadius: '7px',
											display: 'flex',
											alignItems: 'center',
											paddingLeft: '7px',
											fontSize: '13px',
										}}
									>
										Interesado en: {client.interested ? client.interested : 'No especificado'}
									</div>
								</Grid>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Última nota'
										name='description'
										value={formik.values.description}
										onChange={e => {
											formik.handleChange(e);
											setTouch(true);
											isDirty(true);
										}}
										error={!!formik.errors.description}
										multiline
										rows={4}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<Box
						sx={{ position: 'absolute', top: '-30px', right: '15px', display: 'flex', gap: '10px' }}
					>
						<LoadingButton variant='contained' type='submit' loading={isLoading}>
							Guardar
						</LoadingButton>

						{/* <LoadingButton color='error' variant='contained' onClick={() => setAsignVisit(true)}>
							<CalendarMonthIcon />
						</LoadingButton> */}

						{formik.values.state >= 3 ? (
							<LoadingButton color='success' variant='contained' onClick={() => setAsignLot(true)}>
								Asignar lote
							</LoadingButton>
						) : (
							<></>
						)}
					</Box>
				</Card>
			</form>

			<ToastContainer />
		</>
	);
};

function validationSchema() {
	return Yup.object({
		name: Yup.string().required(true),
		lastName: Yup.string(),
		email: Yup.string(),
		cellphone: Yup.string().required(true),
		locality: Yup.string(),
		province: Yup.string(),
		numberId: Yup.string(),
		birthdate: Yup.date(),
		typeDoc: Yup.number(),
		state: Yup.number(),
		description: Yup.string().max(530).required(true),
	});
}

function initialValues(client) {
	const formatToDateInput = birthdate => {
		const [day, month, year] = birthdate.split('-');

		return `${year}-${month}-${day}`;
	};

	return {
		name: client.name,
		lastName: client.lastName,
		email: client.email ? client.email : '',
		cellphone: client.cellphone,
		birthdate: client.birthdate ? formatToDateInput(client.birthdate) : '',
		locality: client.locality ? client.locality : '',
		province: client.province ? client.province : 'nodata',
		numberId: client.numberId ? client.numberId : '',
		address: client.address ? client.address : '',
		typeDoc: client.TypeDocId ? client.TypeDocId : 1,
		state: client.StateId,
		description: '',
	};
}
