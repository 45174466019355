import style from './ChangeDimensions.module.scss';

export default function ChangeDimensions({ lote, setLote }) {
	return (
		<div className={style.container}>
			<div className={style.container__instructions}>
				<p className={style.container__instructions__title}>Instrucciones</p>
				<p>"Enter" para clonar el lote</p>
				<p>"W, A, S, D" para mover el lote</p>
				<p>"8, 6, 2, 4" para cambiar el tamaño del lote</p>
			</div>
			<div className={style.container__divisor}></div>
			<div className={style.container__instructions}>
				<p>"1" para incrementar la velocidad de movimiento</p>
				<p>"2" para reducir la velocidad de movimiento</p>
			</div>

			<div className={style.container__numLote}>
				<span>
					Siguiente lote:
					<input value={lote} onChange={e => setLote(e.target.value)} />
				</span>
			</div>
		</div>
	);
}
