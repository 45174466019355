import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';

import style from './PopupLoteDesarrollo.module.scss';
import { shortenString } from '../../utils/shortenString';

export const PopupLoteDesarrolloEdit = ({ lot, handleEditLot, closePopup }) => {
	const [lotDetail, setLotDetail] = useState(lot);

	const handleChange = ({ target }) => {
		setLotDetail(prev => ({ ...prev, [target.name]: target.value }));
	};

	const handleSave = () => {
		handleEditLot(lot.id, lotDetail);
		closePopup();
	};

	return (
		<Box
			sx={{
				gap: '10px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<div className={style.container_client}>
				{lot?.ContactId && (
					<Link to={`/contacto/${lotDetail.ContactId}`} target='_blank'>
						<Typography variant='button'>
							{shortenString(lot?.Contact?.name + ' ' + lotDetail?.Contact?.lastName)}
						</Typography>
					</Link>
				)}
			</div>

			<TextField
				name='numberLot'
				label='Lote número'
				variant='standard'
				onChange={handleChange}
				value={lotDetail?.numberLot}
			/>

			<TextField
				name='measures'
				label='Dimensiones'
				variant='standard'
				onChange={handleChange}
				value={lotDetail?.measures}
			/>

			<Button variant='contained' size='small' onClick={handleSave}>
				Guardar
			</Button>
		</Box>
	);
};
