import { Link } from 'react-router-dom';
import { Box, TextField, Typography } from '@mui/material';

import { shortenString } from '../../utils/shortenString';
import style from './PopupLoteDesarrollo.module.scss';

export const PopupLoteDesarrollo = ({ contact }) => {
	return (
		<Box
			sx={{
				gap: '10px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<div className={style.container_client}>
				{contact?.ContactId && (
					<Link to={`/contacto/${contact.ContactId}`} target='_blank'>
						<Typography variant='button'>
							{shortenString(contact?.Contact?.name + ' ' + contact?.Contact?.lastName)}
						</Typography>
					</Link>
				)}
			</div>

			<TextField
				name='numberLot'
				label='Lote número'
				variant='standard'
				readOnly={true}
				value={contact?.numberLot}
			/>

			<TextField
				readOnly
				name='measures'
				label='Dimensiones'
				variant='standard'
				value={contact?.measures}
			/>
		</Box>
	);
};
