import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	CircularProgress,
	Button,
	SvgIcon,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ContactProfile, ContactProfileDetails } from '../components/ContactoPerfil';
import { addDniImages, deleteDniImages, getHistoryClient } from '../redux/actions/client';
import { ModalAsignLot, ModalAsignVisit } from '../components/Modals';
import { HistoryContact } from '../components/HistoryContact';
import { DragDrop } from '../components/DragDrop/DragDrop';
import { WarningDialog } from '../components/Dialogs';
import { useAuth } from '../hooks/useAuth';

import 'react-toastify/dist/ReactToastify.css';

export const ContactoPerfil = () => {
	const dispatch = useDispatch();

	const [isDirty, setIsDirty] = useState(false);
	const [warning, setWarning] = useState(false);
	const [asignLot, setAsignLot] = useState(false);
	const [asignVisit, setAsignVisit] = useState(false);

	const [imageDni, setImageDni] = useState();
	const [imageDniBack, setImageDniBack] = useState();

	const [dniFrontLoading, setDniFrontLoading] = useState(false);
	const [dniBackLoading, setDniBackLoading] = useState(false);

	const [deleteDniFrontLoading, setDeleteDniFrontLoading] = useState(false);
	const [deleteDniBackLoading, setDeleteDniBackLoading] = useState(false);

	const { user } = useAuth();
	const isLoading = useSelector(state => state.clientDetail.loading);
	const dataClient = useSelector(state => state.clientDetail.data);

	useEffect(() => {
		function handleBeforeUnload(event) {
			if (isDirty) {
				event.preventDefault();
				event.returnValue = '';
				setWarning(true);
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isDirty]);

	useEffect(() => {
		if (dataClient.imagesDni?.dni_front) {
			setImageDni([
				`https://desarrollos-elemental.s3.amazonaws.com/desarrollos-crm/${dataClient.imagesDni.dni_front}`,
			]);
		}

		if (dataClient.imagesDni?.dni_back) {
			setImageDniBack([
				`https://desarrollos-elemental.s3.amazonaws.com/desarrollos-crm/${dataClient.imagesDni.dni_back}`,
			]);
		}
	}, [dataClient]);

	const handleGoBack = () => {
		if (isDirty) setWarning(true);
		else window.history.back();
	};

	const handleSave = async dniType => {
		const formData = new FormData();

		if (dniType === 'dnifront') {
			setDniFrontLoading(true);

			formData.append('dnifront', imageDni[0]);

			await addDniImages(formData, 'front', dataClient.id);
			dispatch(getHistoryClient(dataClient.id));

			setDniFrontLoading(false);

			toast.success('Imagen guardada correctamente', {
				toastId: 'upload-image-dni-front',
				hideProgressBar: false,
				position: 'top-right',
				closeOnClick: true,
				autoClose: 3000,
				theme: 'light',
			});
		}

		if (dniType === 'dniback') {
			setDniBackLoading(true);

			formData.append('dniback', imageDniBack[0]);

			await addDniImages(formData, 'back', dataClient.id);
			dispatch(getHistoryClient(dataClient.id));

			setDniBackLoading(false);

			toast.success('Imagen subida correctamente', {
				toastId: 'upload-image-dni-back',
				hideProgressBar: false,
				position: 'top-right',
				closeOnClick: true,
				autoClose: 3000,
				theme: 'light',
			});
		}
	};

	const handleDelete = async dniType => {
		if (dniType === 'dnifront') {
			setDeleteDniFrontLoading(true);

			await deleteDniImages('front', dataClient.id);
			dispatch(getHistoryClient(dataClient.id));
			setImageDni([]);

			setDeleteDniFrontLoading(false);

			toast.info('Imagen eliminada correctamente', {
				toastId: 'delete-image-dni-front',
				hideProgressBar: false,
				position: 'top-right',
				closeOnClick: true,
				autoClose: 3000,
				theme: 'light',
			});
		}

		if (dniType === 'dniback') {
			setDeleteDniBackLoading(true);

			await deleteDniImages('back', dataClient.id);
			dispatch(getHistoryClient(dataClient.id));
			setImageDniBack([]);

			setDeleteDniBackLoading(false);

			toast.info('Imagen eliminada correctamente', {
				toastId: 'delete-image-dni-back',
				hideProgressBar: false,
				position: 'top-right',
				closeOnClick: true,
				autoClose: 3000,
				theme: 'light',
			});
		}
	};

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='lg'>
					<Button
						onClick={() => handleGoBack()}
						variant='contained'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: '15px',
							mb: '20px',
						}}
					>
						<SvgIcon>
							<ArrowBackIcon />
						</SvgIcon>
						Volver atrás
					</Button>
					{isLoading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								height: '150px',
								alignItems: 'center',
							}}
						>
							<CircularProgress />
						</div>
					) : !dataClient.User ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '250px',
							}}
						>
							<Typography variant='h6'>Este contacto no pertenece a ningún asesor</Typography>
						</div>
					) : dataClient.User.id !== user.id && user.roleId !== 1 ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '250px',
							}}
						>
							<Typography variant='h6'>
								Este contacto pertenece a: {dataClient.User.fullName}
							</Typography>
						</div>
					) : (
						<Stack spacing={3}>
							<div>
								<Typography variant='h4'>Contacto</Typography>
							</div>
							<div>
								<Grid container spacing={3}>
									<Grid xs={12} md={6} lg={4}>
										<ContactProfile dataClient={dataClient} />
									</Grid>
									<Grid xs={12} md={6} lg={8}>
										<ContactProfileDetails
											user={user}
											client={dataClient}
											isDirty={setIsDirty}
											isLoading={isLoading}
											setAsignLot={setAsignLot}
											setAsignVisit={setAsignVisit}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={3}>
									<Grid
										xs={12}
										md={6}
										lg={4}
										sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
									>
										<DragDrop
											name='dnifront'
											files={imageDni}
											title='DNI delante'
											setFiles={setImageDni}
											loading={dniFrontLoading}
											handleSubmit={handleSave}
											handleDelete={handleDelete}
											text={'Arrastrá y soltá la imagen'}
											loadingDelete={deleteDniFrontLoading}
											accept={'image/png, image/jpeg, image/webp, image/svg+xml'}
										/>

										<DragDrop
											name='dniback'
											title='DNI atrás'
											files={imageDniBack}
											loading={dniBackLoading}
											handleSubmit={handleSave}
											setFiles={setImageDniBack}
											handleDelete={handleDelete}
											text={'Arrastrá y soltá la imagen'}
											loadingDelete={deleteDniBackLoading}
											accept={'image/png, image/jpeg, image/webp, image/svg+xml'}
										/>
									</Grid>

									<Grid xs={12} md={6} lg={8}>
										<HistoryContact />
									</Grid>
								</Grid>
							</div>
						</Stack>
					)}
				</Container>
			</Box>
			<WarningDialog open={warning} setOpen={setWarning} />
			<ModalAsignVisit open={asignVisit} setOpen={setAsignVisit} />
			<ModalAsignLot dataClient={dataClient} open={asignLot} setOpen={setAsignLot} />
		</>
	);
};
