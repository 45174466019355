import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { TextField } from '@mui/material';

import './Calendar.css';

export const CalendarVisit = ({
	setFechaVisita,
	setTime,
	setComment,
	setDevelopment,
	comment,
	development,
}) => {
	const [hora, setHora] = useState('00:00');

	const handleChange = date => {
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		setFechaVisita(`${day}/${month}/${year}`);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
			}}
		>
			<Calendar onChange={handleChange} />
			<div style={{ display: 'flex', gap: '10px' }}>
				<TextField
					sx={{ width: '30%' }}
					fullWidth
					type='time'
					label='Horario'
					name='lastName'
					value={hora}
					step='300'
					onChange={e => {
						setHora(e.target.value);
						setTime(e.target.value);
					}}
				/>
				<TextField
					fullWidth
					label='Desarrollo'
					name='development'
					value={development}
					onChange={e => setDevelopment(e.target.value)}
				/>
			</div>
			<TextField
				fullWidth
				label='Comentario'
				name='comment'
				value={comment}
				onChange={e => setComment(e.target.value)}
				multiline
				rows={3}
			/>
		</div>
	);
};
