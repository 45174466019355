import React, { useState, useEffect, useRef, useReducer } from 'react';
import {
	Box,
	Card,
	styled,
	Button,
	Divider,
	Container,
	CardHeader,
	CircularProgress,
	CardContent,
	Typography,
	TextField,
	SvgIcon,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ToastContainer, toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';
import { Formik, Form, Field } from 'formik';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';

import { Map } from '../components/Map';
import 'react-quill/dist/quill.snow.css';
import { authAxios } from '../utils/authAxios';
import { DesarrolloPerfilMapEdit } from '../components/InteractiveMaps';
import { DesarrolloPerfilLotes, DevelopmentCard } from '../components/Desarrollos';

import 'react-toastify/dist/ReactToastify.css';

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		marginTop: '15px',
		'& input[type=number]': {
			MozAppearance: 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
	'.MuiInputLabel-root': {
		fontSize: '17px',
	},
});

function initialValues(data) {
	return {
		address: data?.address,
		locality: data?.locality,
	};
}

const URL_API = process.env.REACT_APP_URL_API;

export const EditarDesarrollo = () => {
	const data = useSelector(state => state.developmentsUser.developmentPerfil);
	const loading = useSelector(state => state.developmentsUser.loadingPerfil);

	const formikRef = useRef();

	const files = {
		logo: null,
		imagePreview: null,
		errors: {
			logoError: false,
			imagePreviewError: false,
		},
		files: {
			logo: '',
			imagePreview: '',
		},
	};

	const reducerFiles = (state, action) => {
		switch (action.type) {
			case 'SET_LOGO':
				return { ...state, logo: action.payload };
			case 'SET_LOGO_FILE':
				return { ...state, files: { ...state.files, logo: action.payload } };
			case 'SET_LOGO_ERROR':
				return { ...state, errors: { ...state.errors, logoError: action.payload } };
			case 'SET_IMAGE_PREVIEW':
				return { ...state, imagePreview: action.payload };
			case 'SET_IMAGE_PREVIEW_FILE':
				return { ...state, files: { ...state.files, imagePreview: action.payload } };
			case 'SET_IMAGE_PREVIEW_ERROR':
				return { ...state, errors: { ...state.errors, imagePreviewError: action.payload } };
			default:
				return state;
		}
	};

	const [stateFiles, dispatch] = useReducer(reducerFiles, files);

	// Estado para cuando se está mandando el formulario
	const [isSubmitting, setIsSubmitting] = useState(false);

	// Estado para guardar los lotes
	const [lots, setLots] = useState(null);

	// Estado para el input de descripcion
	const [descriptionValue, setDescriptionValue] = useState(data?.development?.description || '');

	// Estado para ver lista de lotes o plano
	const [show, setShow] = useState(false);

	// Estados para la imagen del logo
	const logoFile = useRef(null);

	// Estados para la vista previa de la imagen
	const imagePreviewFile = useRef(null);

	useEffect(() => {
		if (data) {
			setLots(data.lots || []);

			dispatch({ type: 'SET_LOGO', payload: data.logo || null });
			dispatch({ type: 'SET_IMAGE_PREVIEW', payload: data.desarrollo || null });
		}
	}, [data]);

	const handleEditLot = (lotId, lotEdited) => {
		const filteredLots = lots.filter(lot => lot.id !== lotId);
		setLots([...filteredLots, lotEdited]);
	};

	const handleFileUpload = (file, allowedExtensions, maxSizeMB, errorType) => {
		const { name, size } = file;
		const fileExtension = name.split('.').pop().toLowerCase();
		const fileSize = size / (1024 * 1024);

		if (!allowedExtensions.includes(fileExtension) || fileSize > maxSizeMB) {
			dispatch({ type: errorType, payload: true });
			return false;
		}

		return true;
	};

	const handleLogoFile = event => {
		if (!event.target.files[0]) return;
		const file = event.target.files[0];

		if (handleFileUpload(file, ['svg'], 5, 'SET_LOGO_ERROR')) {
			dispatch({ type: 'SET_LOGO', payload: URL.createObjectURL(file) });
			dispatch({ type: 'SET_LOGO_ERROR', payload: false });
			dispatch({ type: 'SET_LOGO_FILE', payload: file });
		}
	};

	const handleImagePreviewFile = event => {
		if (!event.target.files[0]) return;
		const file = event.target.files[0];

		if (handleFileUpload(file, ['jpg', 'jpeg', 'png', 'webp'], 5, 'SET_IMAGE_PREVIEW_ERROR')) {
			dispatch({ type: 'SET_IMAGE_PREVIEW', payload: URL.createObjectURL(file) });
			dispatch({ type: 'SET_IMAGE_PREVIEW_ERROR', payload: false });
			dispatch({ type: 'SET_IMAGE_PREVIEW_FILE', payload: file });
		}
	};

	const handleGoBack = () => window.history.back();

	const handleChangeShow = () => {
		setShow(prev => !prev);
	};

	const handleSubmit = () => {
		formikRef.current?.submitForm();
	};

	return (
		<>
			<Box component='main' sx={{ flexGrow: 1 }}>
				<Container maxWidth='xl'>
					<Box
						component='header'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Button
							onClick={() => handleGoBack()}
							variant='contained'
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								gap: '15px',
								mb: '20px',
							}}
						>
							<SvgIcon>
								<ArrowBackIcon />
							</SvgIcon>
							Volver atrás
						</Button>

						<LoadingButton
							loading={isSubmitting}
							onClick={handleSubmit}
							variant='contained'
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								gap: '15px',
								mb: '20px',
							}}
						>
							<SvgIcon>
								<SaveIcon />
							</SvgIcon>
							Guardar
						</LoadingButton>
					</Box>
					{loading ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								height: '350px',
							}}
						>
							<CircularProgress />
							<Typography sx={{ marginTop: 5 }}>Esto puede tardar unos segundos...</Typography>
						</div>
					) : (
						<Formik
							initialValues={initialValues(data.development)}
							validationSchema={validationSchema}
							innerRef={formikRef}
							onSubmit={async formValue => {
								try {
									setIsSubmitting(true);
									const formData = new FormData();

									formData.append('logo', stateFiles.files.logo);
									formData.append('imagePreview', stateFiles.files.imagePreview);

									formData.append('description', descriptionValue);
									formData.append('lotes', JSON.stringify(lots));

									for (const key in formValue) {
										if (formValue.hasOwnProperty(key)) {
											formData.append(key, formValue[key]);
										}
									}

									await authAxios(
										`${URL_API}/admin/development/${data.development.id}`,
										'axios-put',
										{ 'Content-Type': 'multipart/form-data' },
										formData
									);

									toast.success('Desarrollo actualizado', {
										toastId: 'updated-development',
										hideProgressBar: false,
										position: 'top-right',
										closeOnClick: true,
										autoClose: 3000,
										theme: 'light',
									});
									setIsSubmitting(false);
								} catch (error) {
									toast.error('Hubo un error al intentar actualizar el desarrollo', {
										toastId: 'upload-error-update-development',
										hideProgressBar: false,
										position: 'top-right',
										closeOnClick: true,
										autoClose: 3000,
										theme: 'light',
									});
									console.log(error);
								}
							}}
						>
							{() => (
								<Form>
									<Card>
										<CardContent sx={{ padding: 0 }}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													position: 'relative',
												}}
											>
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'flex-end',
														width: '100%',
														position: 'absolute',
														marginTop: '7px',
														marginRight: '15px',
													}}
												>
													<Button variant='text' component='label'>
														<input
															hidden
															type='file'
															accept='.svg'
															ref={logoFile}
															onChange={handleLogoFile}
														/>
														<UploadIcon />
													</Button>

													{stateFiles.errors.logoError && (
														<>
															<Typography variant='caption' color='red'>
																Formatos: .svg
															</Typography>

															<Typography variant='caption' color='red'>
																Peso máximo: 5mb
															</Typography>
														</>
													)}
												</Box>

												{stateFiles.logo && (
													<img src={stateFiles.logo} alt='Logo' height={320} width={320} />
												)}
											</Box>
										</CardContent>
										<Divider />
									</Card>

									<Card sx={{ mt: 3 }}>
										<CardHeader title='Descripción del desarrollo' />
										<CardContent>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													height: '400px',
												}}
											>
												<ReactQuill
													theme='snow'
													value={descriptionValue}
													style={{ height: '300px' }}
													onChange={setDescriptionValue}
												/>

												<Divider />
											</Box>
										</CardContent>
										<Divider />
									</Card>

									<Card sx={{ mt: 3 }}>
										<CardHeader title='Localización' />
										<CardContent>
											<Box style={{ display: 'flex', gap: 10 }}>
												<Field
													as={TextFieldContact}
													sx={{ width: '50%' }}
													label='Localidad'
													variant='filled'
													name='locality'
													id='locality'
												/>

												<Field
													as={TextFieldContact}
													sx={{ width: '50%' }}
													label='Dirección'
													variant='filled'
													name='address'
													id='address'
												/>
											</Box>

											<Box height={450} mt={3}>
												<Map
													coordinates={data.development.coordinates}
													name={data.development.name}
												/>
											</Box>
										</CardContent>
									</Card>

									<Card sx={{ mt: 3 }}>
										<CardHeader
											title='Lotes'
											action={
												<Button onClick={handleChangeShow} variant='contained'>
													Lista/plano
												</Button>
											}
										/>

										<CardContent sx={{ minHeight: '800px' }}>
											{show ? (
												<DesarrolloPerfilLotes lots={lots} />
											) : (
												lots && (
													<DesarrolloPerfilMapEdit
														handleEditLot={handleEditLot}
														development={data.plane}
														setLots={setLots}
														lots={lots}
													/>
												)
											)}
										</CardContent>
									</Card>

									<Card sx={{ mt: 3, mb: 10 }}>
										<CardHeader title='Imagen preview desarrollos' />

										<CardContent
											sx={{
												display: 'flex',
												gap: 2,
												width: '100%',
											}}
										>
											<Box sx={{ width: '440px' }}>
												<DevelopmentCard
													imagePreview={stateFiles.imagePreview}
													availableLots={data.availableLots}
													locality={data.locality}
													address={data.address}
													totLot={data.totLot}
													name={data.name}
												/>
											</Box>

											<Box>
												<Button variant='text' component='label'>
													<input
														hidden
														type='file'
														ref={imagePreviewFile}
														accept='.jpg,.jpeg,.png,.webp'
														onChange={handleImagePreviewFile}
													/>
													<UploadIcon />
												</Button>

												{stateFiles.errors.imagePreviewError && (
													<Box sx={{ display: 'flex', flexDirection: 'column' }}>
														<Typography variant='caption' color='red'>
															Formatos: jpg, jpeg, png, webp
														</Typography>

														<Typography variant='caption' color='red'>
															Peso máximo: 5mb
														</Typography>
													</Box>
												)}
											</Box>
										</CardContent>
									</Card>
								</Form>
							)}
						</Formik>
					)}
				</Container>
			</Box>

			<ToastContainer />
		</>
	);
};

function validationSchema() {
	return Yup.object({
		measuresLot: Yup.string(),
		lots: Yup.number(),
		locality: Yup.string(),
		address: Yup.string(),
		services: Yup.array(),
	});
}
