import React, { useState, useRef } from 'react';
import {
	Card,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	CardHeader,
	CardContent,
	Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ToastContainer, toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { CreateInteractiveMap, LocationDesarrolloMap } from '../InteractiveMaps';
import { createDevelopment } from '../../redux/actions/admin/developments';
import { DragDrop } from '../DragDrop';

import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

const TextFieldContact = styled(TextField)({
	'&.MuiTextField-root': {
		margin: '5px',
		'& input[type=number]': {
			MozAppearance: 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0,
		},
	},
});

const styleFile = {
	display: 'flex',
	width: 'auto',
	flexDirection: 'column',
};

export const CreateDesarrollo = ({ goBack }) => {
	const dispatch = useDispatch();

	const [logo, setLogo] = useState([]);
	const [planes, setPlanes] = useState(null);
	const [desarrollo, setDesarrollo] = useState([]);

	const [planoError, setPlanoError] = useState(false);

	const [fileNamePlanes, setFileNamePlanes] = useState('');

	const [coordinates, setCoordinates] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [lotes, setLotes] = useState([]);

	// Estados para el input: Descripcion
	const [descriptionValue, setDescriptionValue] = useState(null);

	// Estado que indica si los lotes estan disponibles o no
	const [ready, setReady] = useState(false);

	const planesFile = useRef(null);

	const handlePlanesFile = event => {
		setPlanoError(false);
		if (!event.target.files[0]) return;
		const file = event.target.files[0];
		const { name, size } = file;
		const fileExtension = name.split('.').pop().toLowerCase();
		const fileSize = size / (1024 * 1024);

		if (!['jpg', 'jpeg', 'png', 'svg'].includes(fileExtension)) {
			setPlanoError(true);
			return;
		}

		if (fileSize > 5) {
			setPlanoError(true);
			return;
		}

		setFileNamePlanes(name);
		setPlanes(file);
	};

	const handleCancel = () => {
		setLogo([]);
		setPlanes(null);
		setDesarrollo([]);
		setFileNamePlanes('');
		formik.resetForm();
		goBack(true);
	};

	const handleDeleteImage = type => {
		if (type === 'logo') {
			setLogo([]);
		}

		if (type === 'desarrollo') {
			setDesarrollo([]);
		}
	};

	const handleDeletePlane = () => {
		setPlanes(null);
		setFileNamePlanes('');
	};

	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async (formValue, { resetForm }) => {
			try {
				if (formik.isSubmitting) {
					setIsLoading(true);
				}

				let formData = new FormData();

				if (!ready) {
					setIsLoading(false);
					return toast.error('Debes marcar como listo la creación de los lotes en el plano.', {
						theme: 'colored',
					});
				}

				if (logo.length === 0) {
					setIsLoading(false);
					return toast.error('No se especificó ningún logo', { theme: 'colored' });
				}

				if (planes === null) {
					setIsLoading(false);
					return toast.error('No se especificó ningún plano', { theme: 'colored' });
				}

				formData.append('description', descriptionValue);
				formData.append('lots', JSON.stringify(lotes));
				formData.append('coordinates', coordinates);
				formData.append('desarrollo', desarrollo[0]);
				formData.append('planes', planes);
				formData.append('logo', logo[0]);

				for (const key in formValue) {
					if (formValue.hasOwnProperty(key)) {
						formData.append(key, formValue[key]);
					}
				}

				await dispatch(createDevelopment({ 'Content-Type': 'multipart/form-data' }, formData));
				setIsLoading(false);
				resetForm();

				setLogo([]);
				setPlanes(null);
				setReady(false);
				setDesarrollo([]);
				setFileNamePlanes('');

				toast.success('Desarrollo creado con éxito', { theme: 'colored' });
				return goBack(true);
			} catch (error) {
				setIsLoading(false);
				console.error(error);
				return toast.error('Ha habido un error, intentelo de nuevo!', { theme: 'colored' });
			}
		},
	});

	return (
		<>
			<DialogTitle>Nuevo desarrollo</DialogTitle>
			<DialogContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					width: '100% !important',
					pt: '15px !important',
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Descripción'
						/>

						<CardContent>
							<TextFieldContact
								id='name'
								label='Nombre'
								variant='filled'
								name='name'
								sx={{ width: '40%' }}
								value={formik.values.name}
								onChange={formik.handleChange}
								error={formik.errors.name}
							/>

							<ReactQuill
								theme='snow'
								value={descriptionValue}
								style={{
									height: '200px',
									marginLeft: '5px',
									marginRight: '5px',
									marginTop: '10px',
									marginBottom: '55px',
									borderRadius: '17px',
								}}
								onChange={setDescriptionValue}
							/>

							<TextFieldContact
								id='measuresLot'
								label='Medidas aprox de cada lote'
								variant='filled'
								name='measuresLot'
								value={formik.values.measuresLot}
								onChange={formik.handleChange}
								error={formik.errors.measuresLot}
							/>
						</CardContent>
					</Card>

					<br />
					<br />
					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Localización'
						/>
						<CardContent>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ display: 'flex' }}>
									<TextFieldContact
										id='localidad'
										label='Localidad'
										variant='filled'
										name='locality'
										sx={{ width: '50%' }}
										value={formik.values.locality}
										onChange={formik.handleChange}
										error={formik.errors.locality}
									/>
									<TextFieldContact
										id='address'
										label='Dirección'
										variant='filled'
										name='address'
										sx={{ width: '50%' }}
										value={formik.values.address}
										onChange={formik.handleChange}
										error={formik.errors.address}
									/>
								</div>
								<div>
									<LocationDesarrolloMap setCoordinates={setCoordinates} />
								</div>
							</div>
						</CardContent>
					</Card>
					<br />
					<br />

					<Card
						sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px !important', borderRadius: '15px' }}
					>
						<CardHeader
							sx={{ backgroundColor: '#14213d', color: 'white', opacity: 0.9 }}
							title='Datos adicionales'
						/>

						<CardContent>
							<DragDrop
								name='logo'
								title='Logo'
								save={false}
								files={logo}
								setFiles={setLogo}
								accept={'image/svg+xml'}
								handleDelete={handleDeleteImage}
								text={'Arrastrá y soltá la imagen'}
								style={{ maxWidth: '100%', height: '150px' }}
							/>

							<br />

							<DragDrop
								save={false}
								name='desarrollo'
								title='Preview desarrollo'
								files={desarrollo}
								setFiles={setDesarrollo}
								handleDelete={handleDeleteImage}
								text={'Arrastrá y soltá la imagen'}
								style={{ width: '440px', height: '254px', borderRadius: '30px' }}
								accept={'image/png, image/jpeg, image/webp, image/svg+xml'}
							/>

							<br />

							{planes < 1 && (
								<span style={styleFile}>
									<Button variant='contained' component='label'>
										<UploadFileIcon />
										<input
											hidden
											accept='.jpg, .jpeg, .png, .svg'
											type='file'
											ref={planesFile}
											onChange={handlePlanesFile}
										/>
										<Typography variant='caption'>
											{fileNamePlanes.length < 1 ? 'Plano' : fileNamePlanes}
										</Typography>
									</Button>
									{!planoError ? null : (
										<>
											<Typography variant='caption' color='red'>
												Formatos: .jpg .jpeg .png .svg
											</Typography>
											<Typography variant='caption' color='red'>
												Peso máximo: 5mb
											</Typography>
										</>
									)}
								</span>
							)}

							{planes && (
								<CreateInteractiveMap
									ready={ready}
									imageFile={planes}
									setLotes={setLotes}
									setReady={setReady}
									handleDeletePlane={handleDeletePlane}
								/>
							)}
						</CardContent>
					</Card>
					<DialogActions>
						<Button onClick={() => handleCancel()}>Cancelar</Button>
						<LoadingButton variant='contained' type='submit' loading={isLoading}>
							Añadir
						</LoadingButton>
					</DialogActions>
				</form>
			</DialogContent>
			<ToastContainer />
		</>
	);
};

function initialValues() {
	return {
		name: '',
		measuresLot: '',
		locality: '',
		address: '',
	};
}

function validationSchema() {
	return Yup.object({
		name: Yup.string().required(true),
		measuresLot: Yup.string().required(true),
		locality: Yup.string().required(true),
		address: Yup.string(),
	});
}
