import { authAxios } from '../../../utils/authAxios';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_DEVELOPMENTS_CONFIG = 'GET_ALL_DEVELOPMENTS_CONFIG';
export const CHANGE_LOADER_CONFIG = 'CHANGE_LOADER_CONFIG';
export const DELETE_DEVELOPMENT = 'DELETE_DEVELOPMENT';

export const getAllDevelpomentsConfig = ({ page, perPage }) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_CONFIG });
		const response = await authAxios(`${URL_API}/admin/developments/${page}/${perPage}`, 'get');

		return dispatch({
			type: GET_ALL_DEVELOPMENTS_CONFIG,
			payload: response,
		});
	};
};

export const createDevelopment = (headers, data) => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_CONFIG });
		await authAxios(`${URL_API}/admin/development/lot`, 'axios', headers, data);
	};
};

export const deleteDevelopment = id => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER_CONFIG });
		await authAxios(`${URL_API}/admin/development/${id}`, 'delete');
		const response = await authAxios(`${URL_API}/client/developments`, 'get');
		return dispatch({
			type: GET_ALL_DEVELOPMENTS_CONFIG,
			payload: response,
		});
	};
};

export const downloadDevelopmentData = developmentId => {
	return async function () {
		try {
			const response = await fetch(`${URL_API}/admin/download/development/data`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ id: developmentId }),
			});

			if (response.ok) {
				const blob = await response.blob();
				const url = URL.createObjectURL(blob);

				const date = new Date();
				const fileName = `datos-${('0' + date.getDate()).slice(-2)}-${(
					'0' +
					(date.getMonth() + 1)
				).slice(-2)}-${date.getFullYear()}.xlsx`;

				const a = document.createElement('a');
				a.href = url;
				a.download = fileName;
				a.style.display = 'none';
				document.body.appendChild(a);
				a.click();
				URL.revokeObjectURL(url);
			} else {
				console.error('Error al descargar el archivo CSV');
			}
		} catch (error) {
			console.error('Error al descargar el archivo CSV:', error);
		}
	};
};
