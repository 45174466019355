/*

			FALTA LA FUNCIONALIDAD handleDelete
			FALTA LA FUNCIONALIDAD DE GUARDAR EN LOCALSTORAGE
				TEMPORALMENTE LO TRABAJADO SOBRE EL PLANO!

*/

import {
	MapContainer,
	TileLayer,
	ImageOverlay,
	FeatureGroup,
	Polygon,
	useMapEvents,
	Popup,
} from 'react-leaflet';
import React, { useEffect, useState, useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { EditControl } from 'react-leaflet-draw';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import L, { CRS } from 'leaflet';

import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';

import { PopupLoteDesarrolloEdit } from './PopupLoteDesarrolloEdit';
import ChangeDimensions from './ChangeDimensions';

export const CreateInteractiveMap = ({
	ready,
	setReady,
	setLotes,
	imageFile,
	handleDeletePlane,
}) => {
	const loteRef = useRef(1);
	const mapRef = useRef(null);
	const featureGroupRef = useRef(null);

	const [backgroundImage, setBackgroundImage] = useState(null);
	const [selectedLayer, setSelectedLayer] = useState(null);
	const [imageBounds, setImageBounds] = useState(null);
	const [lots, setLots] = useState([]);

	// Estados de los poligonos/rectangulos del mapa
	const [speedMovement, setSpeedMovement] = useState(0.000001);

	// Dimensiones container padre
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [forceKeyUpdate, setForceKeyUpdate] = useState(0);
	const [center, setCenter] = useState([0, 0]);

	// Ternario para visualizar si se completo el mapeo

	useEffect(() => {
		const loadImage = async () => {
			const image = new Image();
			image.src = URL.createObjectURL(imageFile);

			image.onload = () => {
				const imageWidth = image.width;
				const imageHeight = image.height;

				const southWest = mapRef.current.unproject([0, imageHeight], mapRef.current.getMaxZoom());
				const northEast = mapRef.current.unproject([imageWidth, 0], mapRef.current.getMaxZoom());

				setImageBounds(L.latLngBounds(southWest, northEast));
				setCenter([(southWest.lat + northEast.lat) / 2, (southWest.lng + northEast.lng) / 2]);
				setWidth(imageWidth);
				setHeight(imageHeight);
				setBackgroundImage(image);
				setForceKeyUpdate(prevKey => prevKey + 1);
			};

			return () => {
				image.onload = null;
			};
		};

		loadImage();
	}, [imageFile]);

	useEffect(() => {
		console.log(selectedLayer);
	}, [selectedLayer]);

	useEffect(() => {
		if (backgroundImage === null) return;
		const imageWidth = backgroundImage.width;
		const imageHeight = backgroundImage.height;

		setWidth(imageWidth);
		setHeight(imageHeight);

		setForceKeyUpdate(prevKey => prevKey + 1);
	}, [backgroundImage]);

	const handleCreate = e => {
		const { layer } = e;

		const coordinates = JSON.stringify(layer.getLatLngs());

		featureGroupRef.current.removeLayer(layer);

		setLots(prevLots => {
			const newLot = {
				id: Date.now() + Math.random(),
				numberLot: prevLots.length + 1,
				coordinates,
				selled: 'Disponible',
				measures: '',
			};

			return [...prevLots, newLot];
		});

		loteRef.current += 1;
	};

	const handleEdited = e => {
		const { layers } = e;

		Object.values(layers._layers).forEach(layer => {
			const lotData = layer.options.lot;

			const newCoordinates = layer.getLatLngs();

			setLots(prevLots =>
				prevLots.map(lot =>
					lot.id === lotData.id ? { ...lot, coordinates: JSON.stringify(newCoordinates) } : lot
				)
			);
		});
	};

	const handleDelete = e => {
		const { layers } = e;

		console.log(layers);
	};

	const duplicatePolygon = () => {
		if (!selectedLayer) return;

		const latlngs = selectedLayer.getLatLngs();

		// Verificar que latlngs no sea undefined o vacío
		if (!latlngs || latlngs.length === 0) {
			console.error('No se encontraron coordenadas válidas para duplicar el polígono.');
			return;
		}

		// Crear un nuevo id para el lote duplicado
		const lotId = Date.now() + Math.random();

		// Crear el nuevo lote y añadirlo al estado
		const newLot = {
			id: lotId,
			numberLot: lots.length + 1,
			coordinates: JSON.stringify(latlngs),
			selled: 'Disponible',
			measures: '',
		};

		setLots(prevLots => [...prevLots, newLot]);

		setTimeout(() => {
			const newLayer = Object.values(featureGroupRef.current._layers).find(
				layer => layer.options.lot?.id === lotId
			);

			if (newLayer) {
				setSelectedLayer(newLayer);
			}
		}, 0);
	};

	// const handleSaveLocalStorage = () => {
	// 	const lotsJson = JSON.stringify(lots);
	// 	localStorage.setItem('lotes', lotsJson);
	// };

	const movePolygon = (latlngs, moveStep, direction) => {
		let newCoordinates = latlngs.map(point => {
			if (Array.isArray(point)) {
				// En caso de polígonos complejos con varios anillos
				return movePolygon(point, moveStep, direction);
			}

			const { lat, lng } = point;

			switch (direction) {
				case 'up': // Mover hacia arriba
					return L.latLng(lat + moveStep, lng);
				case 'down': // Mover hacia abajo
					return L.latLng(lat - moveStep, lng);
				case 'left': // Mover hacia la izquierda
					return L.latLng(lat, lng - moveStep);
				case 'right': // Mover hacia la derecha
					return L.latLng(lat, lng + moveStep);
				default:
					return point; // Retornar sin cambios si no coincide la dirección
			}
		});

		if (!newCoordinates[0]) return;
		setLots(prevLots =>
			prevLots.map(lot =>
				lot.numberLot === selectedLayer.options.lot.numberLot
					? { ...lot, coordinates: JSON.stringify(newCoordinates) }
					: lot
			)
		);
	};

	const changeSizePolygon = (latlngs, size, direction) => {
		// Obtenemos el centro aproximado del polígono
		const center = selectedLayer.getBounds().getCenter();

		let newCoordinates = latlngs.map(point => {
			if (Array.isArray(point)) {
				return changeSizePolygon(point, size, direction);
			}

			const { lat, lng } = point;

			let newLat = lat;
			let newLng = lng;

			// Aplicamos un factor multiplicador en función de la dirección
			switch (direction) {
				case 'up': // Extender hacia arriba
					newLat = lat + size * (lat - center.lat);
					break;
				case 'down': // Extender hacia abajo
					newLat = lat - size * (lat - center.lat);
					break;
				case 'left': // Extender hacia la izquierda
					newLng = lng - size * (lng - center.lng);
					break;
				case 'right': // Extender hacia la derecha
					newLng = lng + size * (lng - center.lng);
					break;
				default:
					return point;
			}

			return L.latLng(newLat, newLng);
		});

		if (!newCoordinates[0]) return;

		setLots(prevLots =>
			prevLots.map(lot =>
				lot.numberLot === selectedLayer.options.lot.numberLot
					? { ...lot, coordinates: JSON.stringify(newCoordinates) }
					: lot
			)
		);
	};

	const changeSpeedMovement = type => {
		switch (type) {
			case 'increase':
				setSpeedMovement(prev => prev + 0.000001);
				break;
			case 'decrease':
				setSpeedMovement(prevSpeed => Math.max(0.000001, prevSpeed - 0.000001));
				break;
			default:
				return;
		}
	};

	const keyBindings = {
		w: () => movePolygon(selectedLayer.getLatLngs(), speedMovement, 'up'),
		s: () => movePolygon(selectedLayer.getLatLngs(), speedMovement, 'down'),
		a: () => movePolygon(selectedLayer.getLatLngs(), speedMovement, 'left'),
		d: () => movePolygon(selectedLayer.getLatLngs(), speedMovement, 'right'),
		6: () => changeSizePolygon(selectedLayer.getLatLngs(), 0.01, 'right'),
		2: () => changeSizePolygon(selectedLayer.getLatLngs(), 0.01, 'down'),
		8: () => changeSizePolygon(selectedLayer.getLatLngs(), 0.01, 'up'),
		4: () => changeSizePolygon(selectedLayer.getLatLngs(), 0.01, 'left'),
		Enter: duplicatePolygon,
	};

	const handleClickPolygon = e => {
		if (ready) return;
		setSelectedLayer(e.target);
	};

	useEffect(() => {
		const handleKeyPress = event => {
			if (ready) return;

			if (keyBindings[event.key] && selectedLayer) {
				keyBindings[event.key]();
			}
		};

		window.addEventListener('keydown', handleKeyPress);
		return () => window.removeEventListener('keydown', handleKeyPress);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLayer, speedMovement]);

	const closePopup = () => {
		if (mapRef.current) {
			const map = mapRef.current;
			map.closePopup();
		}
	};

	const MapEvents = () => {
		useMapEvents({
			keypress: e => {
				if (e.originalEvent.key === '1') {
					changeSpeedMovement('increase');
				} else if (e.originalEvent.key === '2') {
					changeSpeedMovement('decrease');
				}
			},
		});
	};

	const handleEditLot = (e, x) => {
		setLots(prevLots =>
			prevLots.map(lot =>
				lot.id === e ? { ...lot, numberLot: x.numberLot, measures: x.measures } : lot
			)
		);
	};

	const handleReadyLots = () => {
		setReady(prev => !prev);
		setSelectedLayer(null);
		setLotes(lots);
		setForceKeyUpdate(prevKey => prevKey + 1);
	};

	return (
		<>
			<Box display='flex' gap={2}>
				<Button
					variant='contained'
					size='small'
					onClick={handleReadyLots}
					color={!ready ? 'success' : 'warning'}
					endIcon={!ready ? <DoneIcon /> : <ClearIcon />}
				>
					{!ready ? 'Listo' : 'Cancelar'}
				</Button>

				<LoadingButton size='small' color='info' variant='contained'>
					Guardar temporalmente
				</LoadingButton>

				<LoadingButton size='small' color='error' variant='contained' onClick={handleDeletePlane}>
					Eliminar
				</LoadingButton>
			</Box>

			<ChangeDimensions lote={loteRef.current} />
			<span style={{ display: 'flex', justifyContent: 'center' }}>
				<MapContainer
					key={forceKeyUpdate}
					ref={mapRef}
					center={center}
					zoom={25}
					scrollWheelZoom={false}
					doubleClickZoom={false}
					zoomControl={false}
					tap={false}
					keyboardPanDelta={false}
					crs={CRS.Simple}
					style={{
						width: `${width}px`,
						height: `${height}px`,
						maxHeight: '80vh',
						border: '1px solid #1b263b',
						borderTop: 'none',
						marginTop: '0px',
						borderRadius: '7px',
						borderTopLeftRadius: '0px',
						borderTopRightRadius: '0px',
						userSelect: 'none',
					}}
				>
					<TileLayer url='' />
					{imageBounds && <ImageOverlay url={backgroundImage.src} bounds={imageBounds} />}

					<MapEvents />

					<FeatureGroup ref={featureGroupRef}>
						<EditControl
							position='topright'
							onCreated={handleCreate}
							onDeleted={handleDelete}
							onEdited={handleEdited}
							draw={{
								circle: false,
								marker: false,
								polyline: false,
								polygon: !ready,
								rectangle: !ready,
								circlemarker: false,
							}}
							edit={{
								edit: !ready,
								remove: !ready,
							}}
						/>

						{lots.map(lot => {
							if (!lot.coordinates) return null;
							if (!mapRef.current) return null;

							let coordinates = JSON.parse(lot.coordinates);

							if (Array.isArray(coordinates[0][0])) {
								coordinates = coordinates.map(polygon =>
									polygon.map(([x, y]) => {
										const latLng = mapRef.current.unproject([x, y], mapRef.current.getMaxZoom());
										return { lat: latLng.lat, lng: latLng.lng };
									})
								);
							}

							return (
								<Polygon
									eventHandlers={{
										click: handleClickPolygon,
									}}
									lot={lot}
									stroke={0}
									key={lot.id}
									positions={coordinates}
									pathOptions={
										selectedLayer?.options?.lot.id === lot.id
											? { fillOpacity: 0.5, color: '#0147b9', strokeWidth: '0px' }
											: { fillOpacity: 0.5, color: '#11D30080', strokeWidth: '0px' }
									}
								>
									<Popup minWidth={150} autoPan={false}>
										<PopupLoteDesarrolloEdit
											lot={lot}
											closePopup={closePopup}
											handleEditLot={handleEditLot}
										/>
									</Popup>
								</Polygon>
							);
						})}
					</FeatureGroup>
				</MapContainer>
			</span>
		</>
	);
};
