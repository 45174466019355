import {
	Card,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	CircularProgress,
	Container,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Scrollbar } from '../Scrollbar/Scrollbar';
import { useParams, Link } from 'react-router-dom';
import { formatDate } from '../../utils/formatDate';

import { getAllLotsDevelopment } from '../../redux/actions/user/developments';
import { ElementMeasures } from './ElementMeasures';
import { Pagination } from './PaginationLots';

export const DesarrolloPerfilLotes = ({ lots }) => {
	const params = useParams();
	const dispatch = useDispatch();

	const pageSize = 50;

	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(0);

	// const lots = useSelector(state => state.developmentsUser.lots);

	useEffect(() => {
		if (lots) {
			setIsLoading(false);
			setTotalPages(Math.ceil(lots.length / pageSize));
		}
	}, [lots]);

	useEffect(() => {
		dispatch(getAllLotsDevelopment(params.id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePageChange = page => {
		setCurrentPage(page);
	};

	const getCurrentPageData = () => {
		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		return lots.slice(startIndex, endIndex);
	};

	return (
		<>
			{isLoading ? (
				<div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
					<CircularProgress />
				</div>
			) : (
				<Box
					component='main'
					sx={{
						flexGrow: 1,
						marginBottom: '20px',
					}}
				>
					<Container maxWidth='xl'>
						<Card>
							<Scrollbar>
								<TableContainer>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell style={{ width: 160 }} align='left'>
													Lote Número
												</TableCell>
												<TableCell style={{ width: 160 }} align='left'>
													Medidas
												</TableCell>
												<TableCell style={{ width: 160 }} align='left'>
													Estado
												</TableCell>
												<TableCell style={{ width: 160 }} align='right'>
													Última modificación
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{getCurrentPageData().map(e => {
												return (
													<TableRow hover key={e.id}>
														<TableCell align='left'>{e.numberLot}</TableCell>
														<TableCell>
															<ElementMeasures measure={e.measures} data={e} />
														</TableCell>

														<TableCell
															align='left'
															sx={{
																maxWidth: '500px !important',
																color:
																	e.selled === 'Vendido'
																		? 'red'
																		: e.selled === 'Disponible'
																		? 'green'
																		: 'orange',
															}}
														>
															{e.selled === 'Vendido' ? (
																<Link
																	to={e.ContactId === null ? null : `/contacto/${e.ContactId}`}
																	style={{
																		textDecoration: 'none',
																		color: 'inherit',
																		cursor: 'pointer',
																	}}
																>
																	{e.Contact.name}
																</Link>
															) : (
																e.selled
															)}
														</TableCell>

														<TableCell align='right'>
															{e.updatedAt ? formatDate(e.updatedAt) : 'ERROR'}
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
									<Pagination
										currentPage={currentPage}
										totalPages={totalPages}
										onPageChange={handlePageChange}
									/>
								</TableContainer>
							</Scrollbar>
						</Card>
					</Container>
				</Box>
			)}
		</>
	);
};
