import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Box,
	Container,
	Stack,
	Unstable_Grid2 as Grid,
	Button,
	SvgIcon,
	// OutlinedInput,
	// Select,
	// MenuItem,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReservasList, ReservasSearchbar } from '../components/Reservas';
import { useAuth } from '../hooks';

export const Reservas = () => {
	const { reservas, count } = useSelector(state => state.allReserves.reserves);
	const [state, setState] = useState('reservasVencidas');
	// const [province, setProvince] = useState('');

	const { user } = useAuth();

	const handleGoBack = () => {
		window.history.back();
	};

	// const handleChangeProvince = e => {
	// 	setProvince(e.target.value);
	// };

	// useEffect(() => {
	// 	console.log(province);
	// }, [province]);

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='lg'>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '20px' }}>
						<Button
							onClick={handleGoBack}
							variant='contained'
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								gap: '15px',
							}}
						>
							<SvgIcon>
								<ArrowBackIcon />
							</SvgIcon>
							Volver
						</Button>

						{/* <Select
							displayEmpty
							inputProps={{ 'aria-label': 'Without label' }}
							value={province}
							onChange={handleChangeProvince}
							input={<OutlinedInput />}
							name='province'
							label='Provincia..'
							sx={{ width: '250px' }}
						>
							<MenuItem disabled value=''>
								<em>Provincia...</em>
							</MenuItem>
							<MenuItem value='Buenos Aires'>Buenos Aires</MenuItem>
							<MenuItem value='Mendoza'>Mendoza</MenuItem>
						</Select> */}
					</Box>

					<Stack spacing={3}>
						<div>
							<Grid container spacing={3}>
								<Grid xs={12} md={6} lg={12}>
									<ReservasSearchbar state={state} setState={setState} />

									<ReservasList reservas={reservas} count={count} state={state} user={user} />
								</Grid>
							</Grid>
						</div>
					</Stack>
				</Container>
			</Box>
		</>
	);
};
